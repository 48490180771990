<script>
import Switcher from "@/components/switcher";
import accountApi from "../apis/accountApi";
import storage from "../storage"
import { Loading } from "element-ui";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  HomeIcon,
  UserIcon,
  KeyIcon,
} from "vue-feather-icons";
import { type } from "os";
import Navbar from '../components/navbar.vue';

/**
 * Auth-login-three component
 */
export default {
  data() {
    return {
      data: {
        email: "",
        password: "",
        remember: false,
      },
      alert: {
        show: false,
        text: ""
      }
    };
  },
  components: {
    Switcher,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    KeyIcon,
    Navbar,
  },
  mounted() {
    let data = storage.localGet("user")
    if (data) {
      this.data = data
    }
    console.log(this.$route.query.code)
    if (this.$route.query.code == 1) {
      this.$message({
        showClose: true,
        message: "Account already activated.",
        type: "success",
        duration: 0
      })
    }
  },
  methods: {
    login() {
      //判断是否可以进行登录操作
      if (this.data.email == "") {
        this.alert.show = true
        //邮箱地址不得为空
        this.alert.text = "Your email cannot be empty"
        return
      } else if (this.data.password == "") {
        this.alert.show = true
        //密码不得为空
        this.alert.text = "Password cannot be empty"
        return
      } else if (this.data.password.length < 8 || this.data.password.length > 16) {
        this.alert.show = true
        //密码长度必须在8~16位之间
        this.alert.text = "Password length must be between 8 and 16 bits"
        return
      }
      const loading = Loading.service({
        lock: true,
        text: 'Sign in...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let loginForm = JSON.parse(JSON.stringify(this.data))
      loginForm.password = btoa(loginForm.password)
      //登录请求
      accountApi.login(loginForm, res => {
        loading.close()
        if (res.code != 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          //储存返回的用户id
          storage.localSet({ userId: res.data })
          //如果确认保存密码
          if (this.data.remember) {
            storage.localSet({ user: JSON.parse(JSON.stringify(this.data)) })
          } else {
            storage.localDel("user")
          }
          this.$router.push("/overview")
        }
      })
    }
  }
};
</script>

<template>
  <div>
    <navbar />
    <!-- Hero Start -->
    <section class="section bg-home bg-circle-gradiant d-flex align-items-center">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login-page bg-white shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Sign in</h4>
                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Your Email <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input type="email" class="form-control pl-5" placeholder="Email" name="email"
                            v-model="data.email" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Password <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input type="password" class="form-control pl-5" placeholder="Password"
                            v-model="data.password" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="d-flex justify-content-between">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1"
                              v-model="data.remember" />
                            <label class="custom-control-label" for="customCheck1">Remember me</label>
                          </div>
                        </div>
                        <p class="forgot-pass mb-0">
                          <router-link to="/auth-re-password" class="text-dark font-weight-bold">Forgot password ?
                          </router-link>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-12" style="margin-bottom: 10px;" v-if="alert.show">
                      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                    </div>
                    <div class="col-lg-12 mb-0">
                      <div class="btn btn-primary btn-block" @click="login">Sign in</div>
                    </div>
                    <div class="col-12 text-center">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2">Don't have an account ?</small>
                        <router-link to="/auth-signup" class="text-dark font-weight-bold">Sign Up</router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!---->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
    <Switcher />
  </div>
</template>
